<template>
  <b-container fluid class="full-height">
    <b-row class="full-height">
      <b-col class="left-side">
        <div class="login">
          <LoginComponent />
        </div>
        <div class="logo">
          <img src="@/assets/img/logoClara.png" />
        </div>
        <div class="max-width">
          <div class="message">
            <div class="img">
              <img src="@/assets/img/foto.png" alt="login" />
              <img src="@/assets/img/foto.png" alt="login" />
            </div>
            <div class="title">
              <h2>{{ $t('views.affiliation_invitation.text_915') }}</h2>
            </div>
            <div class="text">
              <p>
                {{ $t('views.affiliation_invitation.text_916') }}<br />
                {{ $t('views.affiliation_invitation.text_917charge') }}
              </p>
            </div>
            <div class="affiliate">
              <span>FACEBOOK EXPERT</span>
            </div>
            <div class="profit">
              <p>
                {{ $t('views.affiliation_invitation.text_918_charge') }}<span>50%</span> {{ $t('views.affiliation_invitation.text_919_charge') }}<br />
                {{ $t('views.affiliation_invitation.text_920_charge') }} <span>R$ 250,00</span>
              </p>
            </div>
            <div class="btn-register">
              <button @click="handlerLogins('1')"> {{ $t('views.affiliation_invitation.text_921') }} </button>
              <button class="btn-login" @click="handlerLogins('2')">
                {{ $t('views.affiliation_invitation.text_922') }}
              </button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <CallEvaluation/>

  </b-container>
</template>
<script>
import LoginComponent from "@/components/login/LoginComponent";
export default {
  name: "Login",
  components: {
    LoginComponent,
  },
  data: () => ({}),
  emits: ["handlerLoginRegister"],
  methods: {
    handlerLogins(id) {
      this.$root.$emit("handlerLoginRegister", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.left-side {
  background-image: url("~@/assets/img/afiliado.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .login {
    width: 30%;
    position: absolute;
    right: 0;
  }
  .logo {
    img {
      width: 230px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      margin-bottom: 80px;
    }
  }
  .max-width {
    max-width: 75%;
    margin: auto;
    .message {
      width: 50%;
      margin: 0 auto;
      .img {
        display: flex;
        justify-content: center;
        img {
          width: 100px;
        }
      }
      .title {
        text-align: center;
        margin: 20px 0;
        width: 100%;
        h2 {
          color: #fff;
          font-family: "Montserrat";
          font-size: 55px;
          font-weight: 600;
          line-height: 0.9;
        }
      }
      .text {
        margin-bottom: 30px;
        text-align: center;
        p {
          color: #fff;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
        }
      }
      .affiliate {
        text-align: center;
        margin: 45px 0;
        span {
          background-color: #050f13d5;
          border-radius: 30px;
          color: #00ac68;
          font-size: 25px;
          font-family: "Montserrat";
          font-weight: 800;
          padding: 15px 40px;
        }
      }
      .profit {
        text-align: center;
        margin: 30px 0;
        p {
          color: #fff;
          font-family: "Montserrat";
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          span {
            background: -webkit-linear-gradient(#008d95, #009b7c);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: "Montserrat";
            font-size: 25px;
            font-weight: 900;
          }
        }
      }
      .btn-register {
        text-align: center;
        button {
          background-color: #f18534;
          color: #222;
          width: 40%;
          font-family: "Montserrat";
          font-weight: 800;
          padding: 12px 0;
          border: 0;
          border-radius: 20px;
          text-decoration: none;
          cursor: pointer;
          &:hover {
            background-color: #cc6b20;
            transition-duration: 0.5s;
          }
        }
        .btn-login {
          margin-left: 10px;
          border: 2px solid #e27521;
          color: #e27521;
          background: transparent;
          &:hover {
            border: 2px solid #fff;
            color: #fff;
            background: transparent;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .left-side {
    .login {
      width: 50%;
      position: absolute;
      right: 0;
    }
    .logo {
      img {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        margin-bottom: 80px;
      }
    }
    .max-width {
      max-width: 80%;
      margin: auto;
      .message {
        width: 90%;
        margin: 0 auto;
        .img {
          img {
            width: 90px;
          }
        }
        .title {
          h2 {
            font-size: 35px;
          }
        }
        .affiliate {
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .left-side {
    .login {
      width: 50%;
      position: absolute;
      right: 0;
    }
    .logo {
      img {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 50px;
      }
    }
    .max-width {
      max-width: 100%;
      margin: auto;
      .message {
        width: 100%;
        margin: 0 auto;
        .img {
          img {
            width: 90px;
          }
        }
        .title {
          h2 {
            font-size: 35px;
          }
        }
        .affiliate {
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .left-side {
    .login {
      width: 100%;
      position: absolute;
      right: 0;
    }
    .logo {
      img {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 50px;
      }
    }
    .max-width {
      max-width: 100%;
      margin: auto;
      .message {
        width: 100%;
        margin: 0 auto;
        .img {
          img {
            width: 90px;
          }
        }
        .title {
          h2 {
            font-size: 35px;
          }
        }
        .affiliate {
          span {
            font-size: 20px;
          }
        }
        .btn-register {
          text-align: center;
          button {
            width: 80%;
          }
          .btn-login {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>